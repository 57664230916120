<template>
  <div>
    <b-row>
      <b-col cols="12">
        <form-wizard
          color="#7367F0"
          :title="null"
          :subtitle="null"
          shape="square"
          finish-button-text="Submit"
          back-button-text="Previous"
          class="mb-3"
          @on-complete="formSubmitted"
        >
          <tab-content title="สร้างข้อความ">
            <validation-observer ref="accountRules" tag="form">
              <b-row>
                <b-col cols="12" class="mb-2">
                  <h3 class="mb-0">สร้างข้อความ</h3>
                </b-col>
                <b-col md="12" lg="12">
                  <b-col md="12">
                    <h4>ชื่อแคมเปญ</h4>
                    <b-form-group label="" label-for="name">
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="name"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger" v-if="errors.length > 0"
                          >โปรดใส่ ชื่อแคมเปญ</small
                        >
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- model สติกเกอร์*/ -->
                  <b-modal
                    ref="sticker-modal"
                    v-model="dialog"
                    scrollable
                    ok-only
                    ok-title="Accept"
                    size="lg"
                    id="dialog"
                  >
                    <template slot="modal-title">
                      <b-tabs>
                        <b-tab
                          active
                          v-for="(icon, index) in stickers.tabs"
                          :key="index"
                          @click="selecttabs(index)"
                        >
                          <template slot="title">
                            <b-avatar size="lg" :src="icon.icon" />
                          </template>
                        </b-tab>
                      </b-tabs>
                    </template>

                    <div class="demo-inline-spacing">
                      <b-col
                        cols="2"
                        v-for="(item, index2) in stickers.group[tabindex]"
                        :key="index2"
                      >
                        <img
                          :src="
                            'https://stickershop.line-scdn.net/stickershop/v1/sticker/' +
                            item.sticker_id +
                            '/ANDROID/sticker.png;compress=true'
                          "
                          height="60"
                          @click="sendsticker(item)"
                          class="grow mb-10"
                        />
                      </b-col>
                    </div>
                  </b-modal>

                  <b-col md="12">
                    <h2>ข้อความ</h2>
                    <p>คุณสามารถส่งข้อความได้ไม่เกิน 3 ข้อความต่อแคมเปญ</p>
                    <b-row>
                      <h3>เลือกประเภทข้อความ</h3>
                      <br /><br />
                    </b-row>
                    <b-row>
                      <b-col
                        cols="12"
                        v-for="(item, index3) in message_data"
                        :key="index3"
                      >
                        <hr />
                        <div class="blue box ex2">
                          <div class="coral item">
                            <!-- //**** text*/ -->
                            <span
                              class="pink item"
                              @click="
                                (item.mselect = 'text'), openstext(index3)
                              "
                            >
                              <feather-icon
                                size="20"
                                icon="MessageSquareIcon"
                              />
                              Text
                            </span>
                            <!-- //**** sticker*/ -->
                            <span
                              class="pink item"
                              v-b-modal.dialog
                              @click="
                                (item.mselect = 'sticker'),
                                  openstickerbox(index3)
                              "
                            >
                              <feather-icon size="20" icon="SmileIcon" />
                              Sticker
                            </span>
                            <!-- //**** image*/ -->
                            <span
                              class="pink item"
                              @click="
                                (item.mselect = 'image'),
                                  openuploadimagebox(index3)
                              "
                            >
                              <feather-icon size="20" icon="ImageIcon" />
                              Image
                            </span>
                            <!-- //**** video*/ -->
                            <span
                              class="pink item"
                              @click="
                                (item.mselect = 'video'),
                                  openuploadvdobox(index3)
                              "
                            >
                              <feather-icon size="20" icon="YoutubeIcon" />
                              Video
                            </span>
                          </div>
                          <div class="coral item">
                            <span
                              class="pink item"
                              @click="up(index3)"
                              v-if="index3 > 0"
                            >
                              <feather-icon size="20" icon="ChevronUpIcon" />
                            </span>
                            <span
                              class="pink item"
                              @click="down(index3)"
                              v-if="index3 !== message_data.length - 1"
                            >
                              <feather-icon size="20" icon="ChevronDownIcon" />
                            </span>
                            <span
                              class="pink item"
                              @click="del(index3)"
                              v-if="index3 > 0"
                            >
                              <feather-icon size="20" icon="XIcon" />
                            </span>
                          </div>
                        </div>

                        <b-row style="margin-top: -20px">
                          <b-col cols="12" class="mt-3">
                            <div v-if="item.mselect == 'text'">
                              <b-form-textarea
                                id="textarea-state"
                                maxlength="500"
                                placeholder="Enter only 500 characters"
                                rows="5"
                                v-model.trim="item.value"
                              />
                              <br />

                              <div class="text-right" style="margin-top: -15px">
                                <h6 class="text-success">500 ตัวอักษร</h6>
                              </div>
                            </div>

                            <div
                              v-if="item.mselect == 'sticker'"
                              class="text-center"
                            >
                              <b-card
                                border-variant="success"
                                bg-variant="transparent"
                                class="shadow-none"
                              >
                                <div v-if="item.url" style="float: left">
                                  <b-button
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    variant="flat-success"
                                    class="btn-icon"
                                    @click="
                                      removesticker(index3),
                                        openstickerbox(index3)
                                    "
                                  >
                                    <feather-icon icon="XIcon" />
                                  </b-button>
                                  <b-img
                                    :src="item.url"
                                    width="100px"
                                    class="ml-5"
                                  />
                                </div>
                                <div v-else>
                                  <h3>เลือกสติ๊กเกอร์</h3>
                                  <feather-icon
                                    size="100"
                                    icon="SmileIcon"
                                    v-b-modal.dialog
                                    @click="openstickerbox(index3)"
                                  />
                                </div>
                              </b-card>
                            </div>

                            <div
                              v-if="item.mselect == 'image'"
                              class="text-center"
                            >
                              <b-card
                                border-variant="success"
                                bg-variant="transparent"
                                class="shadow-none"
                              >
                                <div v-if="item.url" style="float: left">
                                  <b-button
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    variant="flat-success"
                                    class="btn-icon"
                                    @click="
                                      removesticker(index3),
                                        openuploadimagebox(index3)
                                    "
                                  >
                                    <feather-icon icon="XIcon" />
                                  </b-button>
                                  <img
                                    :src="item.url"
                                    width="200px"
                                    class="ml-5"
                                  />
                                </div>
                                <div v-else>
                                  <vue-dropzone
                                    id="myVueDropzoneimg"
                                    ref="myVueDropzoneimg"
                                    :options="dropzoneOptions"
                                    v-on:vdropzone-success="vsuccess"
                                  >
                                  </vue-dropzone>
                                  <span class="text-left">
                                    <br />รูปแบบไฟล์: JPG, JPEG, PNG <br />
                                    ขนาดไฟล์: ไม่เกิน 10 MB
                                    <b-badge
                                      variant="warning"
                                      class="badge-glow"
                                      >จำนวน 1 รูป
                                    </b-badge>
                                  </span>
                                </div>
                              </b-card>
                            </div>

                            <div
                              v-if="item.mselect == 'video'"
                              class="text-center"
                            >
                              <b-card
                                border-variant="success"
                                bg-variant="transparent"
                                class="shadow-none"
                              >
                                <div v-if="item.url" style="float: left">
                                  <b-button
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    variant="flat-success"
                                    class="btn-icon"
                                    @click="
                                      removesticker(index3),
                                        openuploadvdobox(index3)
                                    "
                                  >
                                    <feather-icon icon="XIcon" />
                                  </b-button>
                                  <video
                                    width="300"
                                    controls
                                    :src="item.url"
                                  ></video>
                                </div>
                                <div v-else>
                                  <vue-dropzone
                                    id="myVueDropzoneVdo"
                                    ref="myVueDropzoneVdo"
                                    :options="dropzoneOptions_vdo"
                                    v-on:vdropzone-success="vsuccess"
                                    @vdropzone-sending="vsending(itemindex)"
                                  >
                                  </vue-dropzone>

                                  <span class="text-left">
                                    <br />รูปแบบไฟล์: MP4, M4V, MOV, AVI, WMV
                                    <br />
                                    ขนาดไฟล์: ไม่เกิน 200 MB
                                    <b-badge
                                      variant="warning"
                                      class="badge-glow"
                                      >จำนวน 1 วีดีโอ
                                    </b-badge>
                                  </span>
                                </div>
                              </b-card>
                            </div>

                            <div v-if="item.mselect == 'json'">
                              <b-form-textarea
                                id="textarea-state-json"
                                placeholder="Enter only 500 characters"
                                rows="8"
                                v-model.trim="item.value"
                              />
                              <div class="text-right">
                                <h6 class="text-success">json</h6>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>

                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="primary"
                      @click="adddata()"
                      v-if="message_data.length < 3"
                    >
                      เพิ่ม
                    </b-button>
                  </b-col>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <tab-content title="กลุ่มเป้าหมาย">
            <b-row>
              <b-col md="8" lg="8">
                <b-card
                  style="background-color: #41d47d; color: #ffffff"
                  @click="filter_all('all')"
                  v-if="targetselect === 'all'"
                >
                  เพื่อนทั้งหมด
                </b-card>

                <b-card
                  :style="style"
                  @click="filter_all('activate_friend')"
                  v-if="
                    targetselect === 'activate_friend' || targetselect === 'all'
                  "
                >
                  ระบคุณสมบัติ
                </b-card>
                <b-card
                  :style="style"
                  @click="filter_all('activate_friend2')"
                  v-if="
                    targetselect === 'activate_friend2' ||
                    targetselect === 'all'
                  "
                >
                  เพื่อนที่เคยใช้งาน WiFi
                </b-card>
                <!-- <b-card
                  :style="style"
                  @click="filter_all('connect_one')"
                  v-if="
                    targetselect === 'connect_one' || targetselect === 'all'
                  "
                >
                  เพื่อนเคยใช้งาน 1 ครั้ง
                </b-card> -->
                <!-- <b-card
                  :style="style"
                  @click="filter_all('return_friend')"
                  v-if="
                    targetselect === 'return_friend' || targetselect === 'all'
                  "
                >
                  เพื่อนที่กลับมาใช้งานซ้ำ 
                </b-card>-->
                <b-card
                  :style="style"
                  @click="filter_all('filter')"
                  v-if="targetselect === 'filter' || targetselect === 'all'"
                >
                  ฟิลเตอร์ด้วยแท็ก
                </b-card>

                <b-card v-if="targetselect === 'activate_friend'">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="filter_all('all')"
                  >
                    <feather-icon icon="ArrowLeftIcon" class="mr-50" />
                    <span class="align-middle">กลับ</span> </b-button
                  ><br />
                  <hr />
                  <b><p>เพศ</p></b>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="filter_data.gender"
                      name="gender"
                      value="all"
                      class="custom-control-primary"
                    >
                      ทั้งหมด
                    </b-form-radio>
                    <b-form-radio
                      v-model="filter_data.gender"
                      name="gender"
                      value="male"
                      class="custom-control-primary"
                    >
                      ชาย
                    </b-form-radio>
                    <b-form-radio
                      v-model="filter_data.gender"
                      name="gender"
                      value="female"
                      class="custom-control-primary"
                    >
                      หญิง
                    </b-form-radio>
                  </div>
                  <br />
                  <div>
                    <b-dropdown
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      text="ช่วงอายุ"
                      variant="outline-primary"
                    >
                      <div align="center">
                        <b-form-checkbox
                          v-for="option in options_age"
                          :key="option.value"
                          v-model="filter_data.age"
                          :value="option.value"
                          name="flavour-3a"
                        >
                          {{ option.text }}
                        </b-form-checkbox>
                      </div>
                    </b-dropdown>
                    <br />
                    <br />
                    อายุ
                    <b v-if="filter_data.age[0]">
                      {{ filter_data.age[0] }} ปี.
                    </b>
                    <b v-if="filter_data.age[1]">
                      ,{{ filter_data.age[1] }} ปี.
                    </b>
                    <b v-if="filter_data.age[2]">
                      ,{{ filter_data.age[2] }} ปี.
                    </b>
                    <b v-if="filter_data.age[3]">
                      ,{{ filter_data.age[3] }} ปี.
                    </b>
                    <b v-if="filter_data.age[4]">
                      ,{{ filter_data.age[4] }} ปี.
                    </b>
                    <b v-if="filter_data.age[5]">
                      ,{{ filter_data.age[5] }} ปี.
                    </b>
                    <b v-if="filter_data.age[6]">
                      ,{{ filter_data.age[6] }} ปี.
                    </b>
                    <b v-if="filter_data.age[7]">
                      ,{{ filter_data.age[7] }} ปี.
                    </b>
                    <br /><br />
                    <hr />
                    <p>OS: ( ปล่อยว่างไว้หากต้องการเลือกทั้งหมด )</p>
                    <div class="demo-inline-spacing">
                      <b-form-checkbox
                        v-model="filter_data.os"
                        value="Windows"
                        class="custom-control-primary"
                      >
                        Windows
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="filter_data.os"
                        value="Mac"
                        class="custom-control-primary"
                      >
                        Mac OS X
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="filter_data.os"
                        value="Linux"
                        class="custom-control-primary"
                      >
                        Linux
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="filter_data.os"
                        value="iOS"
                        class="custom-control-primary"
                      >
                        iOS
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="filter_data.os"
                        value="Android"
                        class="custom-control-primary"
                      >
                        Android
                      </b-form-checkbox>
                    </div>
                    <hr />
                    <p>
                      {{ total_user }} % ต่อทั้งหมด ระบบจากทำการสุ่มจากทั้งหมด
                      {{ user_filter }} คนเหลือ {{ percent }} คน
                    </p>
                    <vue-slider v-model="total_user" />
                  </div>
                </b-card>
                <b-card v-if="targetselect === 'connect_one'">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="filter_all('all')"
                  >
                    <feather-icon icon="ArrowLeftIcon" class="mr-50" />
                    <span class="align-middle">กลับ</span> </b-button
                  ><br />
                  <hr />
                  <b><p>เพศ</p></b>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="filter_data.gender"
                      name="gender"
                      value="all"
                      class="custom-control-primary"
                    >
                      ทั้งหมด
                    </b-form-radio>
                    <b-form-radio
                      v-model="filter_data.gender"
                      name="gender"
                      value="male"
                      class="custom-control-primary"
                    >
                      ชาย
                    </b-form-radio>
                    <b-form-radio
                      v-model="filter_data.gender"
                      name="gender"
                      value="female"
                      class="custom-control-primary"
                    >
                      หญิง
                    </b-form-radio>
                  </div>
                  <br />
                  <div>
                    <b-dropdown
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      text="ช่วงอายุ"
                      variant="outline-primary"
                    >
                      <div align="center">
                        <b-form-checkbox
                          v-for="option in options_age"
                          :key="option.value"
                          v-model="filter_data.age"
                          :value="option.value"
                          name="flavour-3a"
                          id="age_dropdown"
                        >
                          {{ option.text }}
                        </b-form-checkbox>
                      </div>
                    </b-dropdown>
                    <br />
                    <br />
                    อายุ
                    <b v-if="filter_data.age[0]">
                      {{ filter_data.age[0] }} ปี.
                    </b>
                    <b v-if="filter_data.age[1]">
                      ,{{ filter_data.age[1] }} ปี.
                    </b>
                    <b v-if="filter_data.age[2]">
                      ,{{ filter_data.age[2] }} ปี.
                    </b>
                    <b v-if="filter_data.age[3]">
                      ,{{ filter_data.age[3] }} ปี.
                    </b>
                    <b v-if="filter_data.age[4]">
                      ,{{ filter_data.age[4] }} ปี.
                    </b>
                    <b v-if="filter_data.age[5]">
                      ,{{ filter_data.age[5] }} ปี.
                    </b>
                    <b v-if="filter_data.age[6]">
                      ,{{ filter_data.age[6] }} ปี.
                    </b>
                    <b v-if="filter_data.age[7]">
                      ,{{ filter_data.age[7] }} ปี.
                    </b>
                    <br /><br />
                  </div>
                  <hr />
                  <p>OS: ( ปล่อยว่างไว้หากต้องการเลือกทั้งหมด )</p>
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      v-model="filter_data.os"
                      value="Windows"
                      class="custom-control-primary"
                    >
                      Windows
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="filter_data.os"
                      value="Mac"
                      class="custom-control-primary"
                    >
                      Mac OS X
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="filter_data.os"
                      value="Linux"
                      class="custom-control-primary"
                    >
                      Linux
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="filter_data.os"
                      value="iOS"
                      class="custom-control-primary"
                    >
                      iOS
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="filter_data.os"
                      value="Android"
                      class="custom-control-primary"
                    >
                      Android
                    </b-form-checkbox>
                  </div>
                  <hr />
                  <p>
                    {{ total_user }} % ต่อทั้งหมด ระบบจากทำการสุ่มจากทั้งหมด
                    {{ user_filter }} คนเหลือ {{ percent }} คน
                  </p>
                  <vue-slider v-model="total_user" />
                </b-card>
                <b-card v-if="targetselect === 'return_friend'">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="filter_all('all')"
                  >
                    <feather-icon icon="ArrowLeftIcon" class="mr-50" />
                    <span class="align-middle">กลับ</span> </b-button
                  ><br />
                  <hr />
                  <b><p>เพศ</p></b>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="filter_data.gender"
                      name="gender"
                      value="all"
                      class="custom-control-primary"
                    >
                      ทั้งหมด
                    </b-form-radio>
                    <b-form-radio
                      v-model="filter_data.gender"
                      name="gender"
                      value="male"
                      class="custom-control-primary"
                    >
                      ชาย
                    </b-form-radio>
                    <b-form-radio
                      v-model="filter_data.gender"
                      name="gender"
                      value="female"
                      class="custom-control-primary"
                    >
                      หญิง
                    </b-form-radio>
                  </div>
                  <br />
                  <div>
                    <b-dropdown
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      text="ช่วงอายุ"
                      variant="outline-primary"
                    >
                      <div align="center">
                        <b-form-checkbox
                          v-for="option in options_age"
                          :key="option.value"
                          v-model="filter_data.age"
                          :value="option.value"
                          name="flavour-3a"
                        >
                          {{ option.text }}
                        </b-form-checkbox>
                      </div>
                    </b-dropdown>
                    <br />
                    <br />
                    อายุ
                    <b v-if="filter_data.age[0]">
                      {{ filter_data.age[0] }} ปี.
                    </b>
                    <b v-if="filter_data.age[1]">
                      ,{{ filter_data.age[1] }} ปี.
                    </b>
                    <b v-if="filter_data.age[2]">
                      ,{{ filter_data.age[2] }} ปี.
                    </b>
                    <b v-if="filter_data.age[3]">
                      ,{{ filter_data.age[3] }} ปี.
                    </b>
                    <b v-if="filter_data.age[4]">
                      ,{{ filter_data.age[4] }} ปี.
                    </b>
                    <b v-if="filter_data.age[5]">
                      ,{{ filter_data.age[5] }} ปี.
                    </b>
                    <b v-if="filter_data.age[6]">
                      ,{{ filter_data.age[6] }} ปี.
                    </b>
                    <b v-if="filter_data.age[7]">
                      ,{{ filter_data.age[7] }} ปี.
                    </b>
                    <br /><br />
                  </div>
                  <hr />
                  <p>OS: ( ปล่อยว่างไว้หากต้องการเลือกทั้งหมด )</p>
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      v-model="filter_data.os"
                      value="Windows"
                      class="custom-control-primary"
                    >
                      Windows
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="filter_data.os"
                      value="Mac"
                      class="custom-control-primary"
                    >
                      Mac OS X
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="filter_data.os"
                      value="Linux"
                      class="custom-control-primary"
                    >
                      Linux
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="filter_data.os"
                      value="iOS"
                      class="custom-control-primary"
                    >
                      iOS
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="filter_data.os"
                      value="Android"
                      class="custom-control-primary"
                    >
                      Android
                    </b-form-checkbox>
                  </div>
                  <hr />
                  <p>
                    {{ total_user }} % ต่อทั้งหมด ระบบจากทำการสุ่มจากทั้งหมด
                    {{ user_filter }} คนเหลือ {{ percent }} คน
                  </p>
                  <vue-slider v-model="total_user" />
                </b-card>
                <b-card v-if="targetselect === 'filter'">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="filter_all('all')"
                  >
                    <feather-icon icon="ArrowLeftIcon" class="mr-50" />
                    <span class="align-middle">กลับ</span> </b-button
                  ><br />
                  <br />
                  <!-- <hr /> -->
                  <!-- <b><p>เพศ</p></b>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="filter_data.gender"
                      name="gender"
                      value="all"
                      class="custom-control-primary"
                    >
                      ทั้งหมด
                    </b-form-radio>
                    <b-form-radio
                      v-model="filter_data.gender"
                      name="gender"
                      value="male"
                      class="custom-control-primary"
                    >
                      ชาย
                    </b-form-radio>
                    <b-form-radio
                      v-model="filter_data.gender"
                      name="gender"
                      value="female"
                      class="custom-control-primary"
                    >
                      หญิง
                    </b-form-radio>
                  </div> -->
                  <!-- <br /> -->
                  <div>
                    <!-- <div>
                      <b-dropdown
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        text="ช่วงอายุ"
                        variant="outline-primary"
                      >
                        <div align="center">
                          <b-form-checkbox
                            v-for="option in options_age"
                            :key="option.value"
                            v-model="filter_data.age"
                            :value="option.value"
                            name="flavour-3a"
                          >
                            {{ option.text }}
                          </b-form-checkbox>
                        </div>
                      </b-dropdown>
                      <br />
                      <br />
                      อายุ
                      <b v-if="filter_data.age[0]">
                        {{ filter_data.age[0] }} ปี.
                      </b>
                      <b v-if="filter_data.age[1]">
                        ,{{ filter_data.age[1] }} ปี.
                      </b>
                      <b v-if="filter_data.age[2]">
                        ,{{ filter_data.age[2] }} ปี.
                      </b>
                      <b v-if="filter_data.age[3]">
                        ,{{ filter_data.age[3] }} ปี.
                      </b>
                      <b v-if="filter_data.age[4]">
                        ,{{ filter_data.age[4] }} ปี.
                      </b>
                      <b v-if="filter_data.age[5]">
                        ,{{ filter_data.age[5] }} ปี.
                      </b>
                      <b v-if="filter_data.age[6]">
                        ,{{ filter_data.age[6] }} ปี.
                      </b>
                      <b v-if="filter_data.age[7]">
                        ,{{ filter_data.age[7] }} ปี.
                      </b>
                      <br /><br />
                    </div> -->
                    <!-- <hr /> -->
                    <!-- <p>OS: ( ปล่อยว่างไว้หากต้องการเลือกทั้งหมด )</p>
                    <div class="demo-inline-spacing">
                      <b-form-checkbox
                        v-model="filter_data.os"
                        value="Windows"
                        class="custom-control-primary"
                      >
                        Windows
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="filter_data.os"
                        value="Mac"
                        class="custom-control-primary"
                      >
                        Mac OS X
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="filter_data.os"
                        value="Linux"
                        class="custom-control-primary"
                      >
                        Linux
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="filter_data.os"
                        value="iOS"
                        class="custom-control-primary"
                      >
                        iOS
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="filter_data.os"
                        value="Android"
                        class="custom-control-primary"
                      >
                        Android
                      </b-form-checkbox>
                    </div> -->
                    <!-- <hr />
                    <p>
                      {{ total_user }} % ต่อทั้งหมด ระบบจากทำการสุ่มจากทั้งหมด
                      {{ user_filter }} คนเหลือ {{ percent }} คน
                    </p>
                    <vue-slider v-model="total_user" /> -->
                    <b><p>แท็ก</p></b>
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="filter_data.tag_and_or"
                        name="or"
                        value="or"
                        class="custom-control-primary"
                      >
                        ส่งไปยังเพื่อนที่มีแท็กต่อไปนี้
                        <br />
                        อย่างน้อยหนึ่งแท็ก
                        <b-img
                          src="https://bp-assets.line-scdn.net/oaplus-marketing-client/6d4f1dd/img/or-illustration.53d934f5.svg"
                          fluid
                          alt="profile photo"
                        />
                      </b-form-radio>
                      <b-form-radio
                        v-model="filter_data.tag_and_or"
                        name="and"
                        value="and"
                        class="custom-control-primary"
                      >
                        ส่งไปยังเพื่อนที่มีแท็กต่อไปนี้ <br />
                        ทั้งหมด
                        <b-img
                          src="https://bp-assets.line-scdn.net/oaplus-marketing-client/6d4f1dd/img/and-illustration.e0a710cf.svg"
                          fluid
                          alt="profile photo"
                        />
                      </b-form-radio>
                    </div>
                    <br /><br />
                    <b>เลือกแท็กที่ต้องการใช้:</b>

                    <b-form-group>
                      <b-form-tags v-model="value" no-outer-focus>
                        <template
                          v-slot="{ tags, disabled, addTag, removeTag }"
                        >
                          <ul
                            v-if="tags.length > 0"
                            class="list-inline d-inline-block mb-1"
                          >
                            <li
                              v-for="tag in tags"
                              :key="tag"
                              class="list-inline-item"
                              style="padding-top: 5px"
                            >
                              <b-form-tag
                                :title="tag"
                                :disabled="disabled"
                                variant="primary"
                                @remove="removeTag(tag)"
                              >
                                {{ tag }}
                              </b-form-tag>
                            </li>
                          </ul>

                          <b-dropdown
                            size="sm"
                            variant="outline-secondary"
                            block
                            menu-class="w-100"
                          >
                            <template v-slot:button-content>
                              <b-icon icon="tag-fill" /> Choose tags
                            </template>
                            <b-dropdown-form @submit.stop.prevent="() => {}">
                              <b-form-group
                                label-for="tag-search-input"
                                label="Search tags"
                                label-cols-md="auto"
                                class="mb-0"
                                label-size="sm"
                                :description="searchDesc"
                                :disabled="disabled"
                              >
                                <b-form-input
                                  id="tag-search-input"
                                  v-model="search"
                                  type="search"
                                  size="sm"
                                  autocomplete="off"
                                />
                              </b-form-group>
                            </b-dropdown-form>
                            <b-dropdown-divider />
                            <div
                              class="demo-inline-spacing"
                              style="padding-left: 10px"
                            >
                              <b-badge
                                v-for="option in availableOptions"
                                :key="option"
                                @click="onOptionClick({ option, addTag })"
                                style="padding-top: 5px"
                              >
                                {{ option }}
                              </b-badge>
                            </div>

                            <b-dropdown-text
                              v-if="availableOptions.length === 0"
                            >
                              There are no tags available to select
                            </b-dropdown-text>
                          </b-dropdown>
                        </template>
                      </b-form-tags>
                    </b-form-group>
                  </div>
                </b-card>
                <b-card v-if="targetselect === 'activate_friend2'">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="filter_all('all')"
                  >
                    <feather-icon icon="ArrowLeftIcon" class="mr-50" />
                    <span class="align-middle">กลับ</span> </b-button
                  ><br />
                  <br />

                  <div style="  float: left;">
                    <input
                      id="option-one-all"
                      v-model="filter_data.period"
                      value="All"
                      checked="checked"
                      type="radio"
                    />
                    <label for="option-one-all"> <span></span> ทั้งหมด </label>
                  </div>
                  <div style="  float: left;">
                    <input
                      id="option-two7"
                       v-model="filter_data.period"
                      value="7"
                      type="radio"
                    />
                    <label for="option-two7">
                      <span></span> 7 วันที่ผ่านมา
                    </label>
                  </div>

                  <div >
                    <input
                      id="option-one14"
                       v-model="filter_data.period"
                      value="14"
                      checked="checked"
                      type="radio"
                    />
                    <label for="option-one14">
                      <span></span> 14 วันที่ผ่านมา
                    </label>
                  </div> <br>
                  <div style="  float: left;">
                    <input
                      id="option-two30"
                       v-model="filter_data.period"
                      value="30"
                      type="radio"
                    />
                    <label for="option-two30">
                      <span></span>30 วันที่ผ่านมา
                    </label>
                  </div>

                  <div style="  float: left;">
                    <input
                      id="option-one7-14"
                       v-model="filter_data.period"
                      value="7-14"
                      checked="checked"
                      type="radio"
                    />
                    <label for="option-one7-14">
                      <span></span> 7-14 วันที่ผ่านมา
                    </label>
                  </div>
                  <div >
                    <input
                      id="option-two15-30"
                       v-model="filter_data.period"
                      value="15-30"
                      type="radio"
                    />
                    <label for="option-two15-30">
                      <span></span> 15-30 วันที่ผ่านมา
                    </label>
                  </div><br>
                  <div style="  float: left;">
                    <input
                      id="option-two-up30"
                       v-model="filter_data.period"
                      value="up-30"
                      type="radio"
                    />
                    <label for="option-two-up30">
                      <span></span> มากกว่า 30 วัน
                    </label>
                  </div>
                </b-card>
              </b-col>
              <b-col md="4" lg="4">
                <b-card no-body>
                  <b-card-header class="pb-0">
                    <p>กลุ่มเป้าหมายโดยประมาณ</p>
                    <p>จำนวน: {{ user_filter }} คน</p>
                    <br />

                    <p>เพื่อนทั้งหมด: {{ user_total }} คน</p>
                    <br />
                    <p>percent:{{ total_user }}% ( {{ percent }} คน)</p>
                    <br />
                  </b-card-header>
                  <b-card-body>
                    <b-row>
                      <b-col
                        sm="2"
                        class="d-flex flex-column flex-wrap text-center"
                      >
                      </b-col>
                      <b-col sm="10" class="d-flex justify-content-center">
                        <vue-apex-charts
                          type="radialBar"
                          height="270"
                          :options="supportTrackerRadialBar.chartOptions"
                          :series="supportTrackerRadialBar.series"
                        />
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </tab-content>

          <tab-content title="กำหนดการ">
            <div class="step3">
              <div>
                <input
                  id="option-one"
                  v-model="broadcast_now"
                  value="false"
                  checked="checked"
                  type="radio"
                />
                <label for="option-one"> <span></span> บรอดแคสต์ตอนนี้ </label>
              </div>
              <br />
              <div>
                <input
                  id="option-two"
                  v-model="broadcast_now"
                  value="true"
                  type="radio"
                />
                <label for="option-two"> <span></span> เลือกช่วง </label>
              </div>

              <b-row style="margin-left: 30px" id="day_broadcast">
                <b-col cols="6">
                  <b-form-group label="วันบรอดแคสต์" label-for="date-start">
                    <b-form-datepicker
                      id="example-datepicker"
                      v-model="startDate"
                      class="mb-1"
                    />

                    <b-alert
                      v-if="check.startDate == true"
                      variant="danger"
                      show
                    >
                      <span>Please Enter</span>
                    </b-alert>
                  </b-form-group>
                </b-col>

                <b-col cols="6">
                  <b-form-group label=" เวลาบรอดแคสต์" label-for="date-end">
                    <!-- <b-form-timepicker
                      id="timepicker-buttons"
                      now-button
                      reset-button
                      locale="en"
                      v-model="endDate"
                    /> -->
                    <flat-pickr
                      v-model="endDate"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                      }"
                    />

                    <b-alert v-if="check.endDate == true" variant="danger" show>
                      <span>Please Enter</span>
                    </b-alert>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </tab-content>
        </form-wizard>
      </b-col>
      <b-col cols="4">
        <div class="green-box">
          <previwe ref="datapreviwe"> </previwe>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { TheMask } from "vue-the-mask";
import Ripple from "vue-ripple-directive";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import api from "@/api";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import flatPickr from "vue-flatpickr-component";
import previwe from "./preview.vue";
import VueSlider from "vue-slider-component";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BAvatar,
  VBModal,
  BForm,
  BCard,
  BCardText,
  BCardTitle,
  BCardHeader,
  BCardBody,
  BLink,
  BButton,
  BTab,
  BTabs,
  BFormTextarea,
  BImg,
  BBadge,
  BFormCheckbox,
  BFormText,
  BFormDatalist,
  BFormRadio,
  BFormSelect,
  BAlert,
  BFormSpinbutton,
  BFormTags,
  BDropdown,
  BDropdownForm,
  BDropdownDivider,
  BDropdownItem,
  BFormTag,
  BDropdownText,
  BFormCheckboxGroup,
  BCardCode,
  BFormTimepicker,
  BFormDatepicker,
  BFormRadioGroup,
} from "bootstrap-vue";
import { required } from "@validations";

export default {
  components: {
    VueApexCharts,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // 3rd Party
    VueSlider,
    dir: "ltr",
    TheMask,
    BAvatar,
    VuePerfectScrollbar,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BForm,
    BCard,
    BCardText,
    BLink,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BTab,
    BTabs,
    BFormTextarea,
    vueDropzone: vue2Dropzone,
    BImg,
    BBadge,
    BFormGroup,
    BFormCheckbox,
    BFormText,
    BFormDatalist,
    flatPickr,
    BFormRadio,
    BFormSelect,
    BAlert,
    previwe,
    BFormSpinbutton,
    BFormInvalidFeedback,
    BFormTags,
    BDropdown,
    BDropdownForm,
    BDropdownDivider,
    BDropdownItem,
    BFormTag,
    BDropdownText,
    BFormCheckboxGroup,
    BCardCode,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormTimepicker,
    BFormDatepicker,
    BFormRadioGroup,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      trackerData: {},
      supportTrackerRadialBar: {
        series: [83],
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: "65%",
              },
              track: {
                background: "#fff",
                strokeWidth: "100%",
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: "#5e5873",
                  fontSize: "1rem",
                },
                value: {
                  offsetY: 15,
                  color: "#5e5873",
                  fontSize: "1.714rem",
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ["Completed Tickets"],
        },
      },
      required,
      dialog: null,
      dropzoneOptions: {
        // url: "http://127.0.0.1:8000/api/uploadmedia_campain",
        url: "https://api.socio.co.th/api/uploadmedia_campain",
        method: "POST",
        params: {
          access_token: localStorage.getItem("access_token"),
          id: this.new_id,
        },
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        parallelUploads: 1,
        maxFiles: 1,
        maxFilesize: 10,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        addRemoveLinks: true,
        dictDefaultMessage: "อัพโหลดรูปภาพ",
        acceptedFiles: ".png,.jpg,.jpeg",
      },

      dropzoneOptions_vdo: {
        // url: "http://127.0.0.1:8000/api/uploadmedia_campain",
        url: "https://api.socio.co.th/api/uploadmedia_campain",
        method: "POST",
        params: {
          access_token: localStorage.getItem("access_token"),
          id: this.new_id,
        },
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        parallelUploads: 1,
        maxFiles: 1,
        maxFilesize: 200,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        addRemoveLinks: true,
        dictDefaultMessage: "อัพโหลดวิดีโอ",
        acceptedFiles: ".mp4,.m4v,.mov,.avi,.wmv",
      },
      tabindex: 0,
      message_data: [],
      stickers: { tabs: [], group: [] },
      imageurl: "",
      videourl: "",
      mselect: "text",
      store_branch: "",
      optionBranch: [],
      targetselect: "all",
      name: "",
      startDate: "",
      startDate_save: "",
      endDate: "",
      data_users: "",
      total_user: 100,
      percent: 100,
      dir: "ltr",

      check: {
        startDate: false,
        endDate: false,
        name: false,
        message_data: false,
        store_branch: false,
      },
      options: [],
      search: "",
      value: [],
      style: null,
      filter_data: {
        condition: "all",
        age: ["18-24", "25-34", "35-44", "45-54", "55-64", "65-100"],
        gender: "all",
        tag_and_or: "or",
        value: [],
        period:"All",
        os: ["Windows", "Mac", "Linux", "iOS", "Android"],
      },
      user_filter: 0,
      user_total: 0,
      options_age: [
        { text: "18-24 ปี", value: "18-24" },
        { text: "25-34 ปี", value: "25-34" },
        { text: "35-44 ปี", value: "35-44" },
        { text: "45-54 ปี", value: "45-54" },
        { text: "55-64 ปี", value: "55-64" },
        { text: "ตั้งแต่ 65 ปีขึ้นไป", value: "65-100" },
      ],
      months_th: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      months_th_mini: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
      broadcast_now: "false",
      selected: "All",
      options2: [
        { text: "ทั้งหมด", value: "All" },
        { text: "7 วันที่ผ่านมา", value: "7" },
        { text: "14 วันที่ผ่านมา", value: "14" },
        { text: "30 วันที่ผ่านมา", value: "30" },
        { text: "7-14 วันที่ผ่านมา", value: "7-14" },
        { text: "15-30 วันที่ผ่านมา", value: "15-30" },
        { text: "มากกว่า 30 วัน", value: "30" },
      ],
    };
  },

  watch: {
    total_user: {
      handler: function (val, oldVal) {
        this.percent = Math.ceil((this.total_user * this.user_filter) / 100);
      },
      deep: true,
    },
    user_filter: {
      handler: function (val, oldVal) {
        this.percent = Math.ceil((this.total_user * this.user_filter) / 100);
      },
      deep: true,
    },
    message_data: {
      handler: function (val, oldVal) {
        this.preview();
      },
      deep: true,
    },
    filter_data: {
      handler: function (val, oldVal) {
        console.log("oldVal :>> ", oldVal);
        console.log("val :>> ", val);
        this.filter();
      },
      deep: true,
    },
    value: {
      handler: function (val, oldVal) {
        this.filter_data.value = val;
      },
      deep: true,
    },
    broadcast_now: {
      handler: function (val, oldVal) {
        console.log("val :>> ", val);
        if (val === "false") {
          document.getElementById("day_broadcast").style.display = "none";
        } else {
          document.getElementById("day_broadcast").style.display = "";
        }
      },
      deep: true,
    },
    startDate: {
      handler: function (val, oldVal) {
        console.log("val :>> ", val);
        this.startDate_save = val;
        let day = new Date(this.startDate_save).getDate();
        let month = new Date(val).getMonth();
        let year = new Date(val).getFullYear() + 543;
        // example-datepicker__value_
        console.log("day :>> ", day);
        console.log("month :>> ", this.months_th[month]);
        this.months_th[month - 1];
        console.log("year :>> ", year + 543);
        document.getElementById("example-datepicker__value_").innerHTML =
          day + " " + this.months_th[month] + " " + year;
        document.getElementById("__BVID__805").innerHTML =
          day + " " + this.months_th[month] + " " + year;
      },
      deep: true,
    },
  },
  created() {
    window.onbeforeunload = function () {
      return "handle your events or msgs here";
    };
  },
  mounted() {
    this.message_data.push({ mselect: "text", value: "" });
    this.loadsticker();
    this.get_brach();
    this.get_tag();
    this.filter();
    document.getElementById("day_broadcast").style.display = "none";
  },
  computed: {
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase();
    },
    availableOptions() {
      const { criteria } = this;

      // Filter out already selected options
      const options = this.options.filter(
        (opt) => this.value.indexOf(opt) === -1
      );
      if (criteria) {
        // Show only options that match criteria
        return options.filter(
          (opt) => opt.toLowerCase().indexOf(criteria) > -1
        );
      }

      // Show all options available
      return options;
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return "There are no tags matching your search criteria";
      }
      return "";
    },
  },
  methods: {
    filter() {
      // console.log("filter()", this.filter_data);
      const params = {
        store_id: localStorage.getItem("store_id"),
        filter_data: this.filter_data,
      };
      console.log("params :>> ", params);
      var access_token = localStorage.getItem("accessToken");
      api
        .post("filter_data_broadcast", params, {
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response filter data :>> ", response.data);
          var user_all =
            (response.data.user_filter * 100) / response.data.user_all;
          if (user_all < 1 && response.data.user_filter > 0) {
            user_all = 1;
          }
          this.supportTrackerRadialBar.series = [user_all.toFixed()];
          this.user_filter = response.data.user_filter;
          this.user_total = response.data.user_all;
          this.data_users = response.data.data;

          this.$forceUpdate();
        });
    },

    get_tag() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      var access_token = localStorage.getItem("accessToken");
      api
        .post("wifimessage_getTag", params, {
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.options = response.data;
        });
    },
    filter_all(text) {
      this.targetselect = text;
      this.filter_data.condition = text;

      if (text == "all") {
        console.log("text :>> ", text);
        this.style = null;
      } else {
        this.style = "background-color: #41d47d; color: #ffffff";
      }
      if (text == "activate_friend") {
        console.log("activate_friend");
      }
      if (text == "connect_one") {
        console.log("connect_one");
      }
      if (text == "return_friend") {
        console.log("return_friend");
      }
      if (text == "filter") {
        console.log("filter");
      }
    },
    onOptionClick({ option, addTag }) {
      addTag(option);
      this.search = "";
    },
    preview() {
      this.$refs.datapreviwe.data_preview(this.message_data);
    },
    up(index) {
      var temp = this.message_data[index - 1];
      var temp2 = this.message_data[index];

      this.message_data.splice(index, 1, temp);
      this.message_data.splice(index - 1, 1, temp2);
      var tmpimage = this.uploadimg[index];
      this.uploadimg[index] = this.uploadimg[index - 1];
      this.uploadimg[index - 1] = tmpimage;

      var tmpvdo = this.uploadvdo[index];
      this.uploadvdo[index] = this.uploadvdo[index - 1];
      this.uploadvdo[index - 1] = tmpvdo;

      //  this.myVueDropzone.removeClass('dz-started');
    },
    down(index) {
      var temp = this.message_data[index + 1];
      var temp2 = this.message_data[index];

      this.message_data.splice(index, 1, temp);
      this.message_data.splice(index + 1, 1, temp2);
      var tmpimage = this.uploadimg[index];
      this.uploadimg[index] = this.uploadimg[index + 1];
      this.uploadimg[index + 1] = tmpimage;
      //     this.myVueDropzone.removeClass('dz-started');
      var tmpvdo = this.uploadvdo[index];
      this.uploadvdo[index] = this.uploadvdo[index + 1];
      this.uploadvdo[index + 1] = tmpvdo;
    },
    del(index) {
      this.message_data.splice(index, 1);
      this.uploadimg[index] = "";
      this.uploadvdo[index] = "";
    },
    addusername(index) {
      if (this.message_data[index].value === null) {
        this.message_data[index].value = "[.....$username....]";
      } else {
        this.message_data[index].value =
          this.message_data[index].value + "[.....$username....]";
      }
    },
    adddata() {
      // console.log(this.message_data);
      if (this.message_data.length >= 3) {
        this.$swal({
          title: "เกิดข้อผิดพลาด!",
          text: " ท่านสามารถกำหนดข้อความได้ไม่เกิน 3 Bubbles",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        });
      } else {
        this.message_data.push({ mselect: "text", value: null });
      }
    },
    vsuccess: function (file, response) {
      console.log("vsuccess", response);
      this.message_data[this.itemindex].url = response;
      this.message_data[this.itemindex].value = response;
      // console.log("this.message_data :>> ", this.message_data);
      this.$forceUpdate();
    },
    selecttabs(index) {
      this.tabindex = index;

      // alert(index);
    },
    loadsticker() {
      var access_token = localStorage.getItem("accessToken");
      api
        .post("getsticker", [], {
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // console.log(response.data);
          this.stickers = response.data;
        });
    },
    get_brach() {
      const params = {
        store_id: localStorage.getItem("store_id"),
      };
      var access_token = localStorage.getItem("accessToken");
      api
        .post("store_branch", params, {
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.optionBranch = response.data;
          // console.log("optionBranch :>> ", this.optionBranch);
        });
    },
    sendsticker(sticker) {
      let sticker_url =
        "https://stickershop.line-scdn.net/stickershop/v1/sticker/" +
        sticker.sticker_id +
        "/ANDROID/sticker.png";
      this.dialog = false;
      this.message_data[this.itemindex].value = sticker.sticker_id;
      this.message_data[this.itemindex].url = sticker_url;
    },
    removesticker(index) {
      console.log(index);
      this.message_data[index].url = null;
      this.message_data[index].value = null;
      this.key += 1;
      this.$forceUpdate();
      console.log("this.message_data :>> ", this.message_data[index]);
    },
    stickergroupselect(group) {
      this.stickergroup = group;
    },
    openstickerbox(index) {
      this.$refs["sticker-modal"].show();
      // console.log("openstickerbox index :>> ", index);
      this.itemindex = index;
    },
    openstext(index) {
      this.itemindex = index;
      this.message_data[this.itemindex].url = "";
      this.message_data[this.itemindex].value = "";
    },
    openstickerbox(index) {
      this.itemindex = index;
      this.message_data[this.itemindex].url = "";
      this.message_data[this.itemindex].value = "";
    },
    openuploadimagebox(index) {
      this.itemindex = index;
      this.message_data[this.itemindex].url = "";
      this.message_data[this.itemindex].value = "";
    },
    openuploadvdobox(index) {
      console.log("itemindex :>> ", this.itemindex);
      this.itemindex = index;
      this.message_data[this.itemindex].url = "";
      this.message_data[this.itemindex].value = "";
    },
    openjson(index) {
      this.itemindex = index;
      this.message_data[this.itemindex].url = "";
      this.message_data[this.itemindex].value = "";
    },
    formSubmitted() {
      const params = {
        store_id: localStorage.getItem("store_id"),
        name: this.name,
        message_data: this.message_data,
        startDate: this.startDate_save,
        endDate: this.endDate,
        gender: this.filter_data.gender,
        age: this.filter_data.age,
        condition: this.filter_data.condition,
        tag_and_or: this.filter_data.tag_and_or,
        tag: this.filter_data.value,
        os: this.filter_data.os,
        store_branch: this.store_branch,
        data_users: this.data_users,
        total_user: this.total_user,
        percent: this.percent,
        broadcast_now: this.broadcast_now,
      };

      console.log("params :>> ", params);
      var access_token = localStorage.getItem("accessToken");
      if (
        this.name &&
        this.startDate_save &&
        this.endDate &&
        this.message_data &&
        this.broadcast_now === "true"
      ) {
        this.$swal({
          title: "ยืนยันการบันทึกข้อมูล",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.isConfirmed) {
            api
              .post("submit_broadcast_message", params, {
                headers: {
                  Authorization: "Bearer " + access_token,
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                this.$swal({
                  title: "บันทึกสำเร็จ!",
                  text: "บันทึกข้อมูลของท่านเรียบร้อย",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                this.$router.push({ name: "BroadcastMessage" });
              });
          }
        });
      } else if (
        this.name &&
        this.message_data &&
        this.broadcast_now === "false"
      ) {
        this.$swal({
          title: "ยืนยันการบันทึกข้อมูล",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.isConfirmed) {
            api
              .post("submit_broadcast_message", params, {
                headers: {
                  Authorization: "Bearer " + access_token,
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                this.$swal({
                  title: "บันทึกสำเร็จ!",
                  text: "บันทึกข้อมูลของท่านเรียบร้อย",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                this.$router.push({ name: "BroadcastMessage" });
              });
          }
        });
      } else {
        if (!this.name) {
          this.check.name = true;
          // console.log("name null");
        }
        if (!this.startDate) {
          this.check.startDate = true;
          // console.log("startDate null");
        }
        if (!this.endDate) {
          this.check.endDate = true;
          // console.log("endDate null");
        }
        if (!this.store_branch) {
          this.check.store_branch = true;
          // console.log("endDate null");
        }

        this.$swal({
          title: "Error!",
          text: " ข้อมูลไม่ถูกต้อง โปรดตรวจสอบใหม่อีกครั้ง",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/vue/libs/vue-slider.scss";
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
.dropdown-menu {
  background-color: aqua !important;
}
.pink {
  background-color: #e8e8e8;
  margin: 10px;
  padding: 4px;
  border: 2px solid rgb(208, 208, 208);
  border-radius: 10px;
  color: #000000;
}
.step3 {
}
input[type="radio"] {
  display: none;
}
input[type="radio"] + label {
  font-weight: 400;
  font-size: 14px;
}
input[type="radio"] + label span {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: -2px 10px 0 0;
  vertical-align: middle;
  cursor: pointer;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid #000000;
}
input[type="radio"] + label span {
  background-color: #fff;
}
input[type="radio"]:checked + label {
  color: #333;
  font-weight: 700;
}
input[type="radio"]:checked + label span {
  background-color: #15bc5d;
  border: 1px solid #000000;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}
input[type="radio"] + label span,
input[type="radio"]:checked + label span {
  -webkit-transition: background-color 0.24s linear;
  -o-transition: background-color 0.24s linear;
  -moz-transition: background-color 0.24s linear;
  transition: background-color 0.24s linear;
}
</style>
