<template>
  <div>
    <b-card class="chat-widget" no-body v-if="show">
      <b-card-header
        style="background-color: #353a40; color: #fff"
        @click="show = !show"
      >
        <div class="d-flex align-items-center" style="color: #fff">
          <feather-icon icon="ChevronDownIcon" size="30" />
          <h4 class="mb-0" style="color: #fff"><b>ดูเพิ่มเติม</b></h4>
        </div>
        <feather-icon icon="AlertOctagonIcon" size="18" />
      </b-card-header>

      <section class="chat-app-window" style="background-color: #89a8d5">
        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
          style="height: 525px; background-color: #89a8d5; color: #000"
        >
          <div style="margin-bottom: 20em; margin-left: -26px;">
            <div
              v-for="(show, index) in message_show"
              :key="index"
              style="margin-bottom: 2em"
            >
              <div v-if="show.value">
                <b-card-header @click="cilck_show = false">
                  <div class="d-flex align-items-center" style="color: #fff">
                    <b-avatar
                      size="40"
                      :src="lineoa_logo"
                      class="avatar-border-2 box-shadow-1 mr-1"
                      variant="transparent"
                    />
                    <h5 class="mb-0" style="color: #fff">
                      <b>{{ lineoa_name }}</b>
                    </h5>
                  </div>
                </b-card-header>

                <b-card
                  v-if="show.mselect == 'text'"
                  style="margin-left: 5em; margin-top: -1em"
                >
                  {{ show.value }}
                </b-card>
                <div
                  v-else-if="show.mselect == 'sticker'"
                  style="margin-left: 7em; margin-top: -1em"
                >
                  <b-img :src="show.url" width="100px" />
                </div>
                <div
                  v-else-if="show.mselect == 'image'"
                  style="margin-left: 5em; margin-top: -1em"
                >
                  <b-img
                    style="border-radius: 10px"
                    :src="show.url"
                    width="250px"
                  />
                </div>
                <div
                  v-else-if="show.mselect == 'video'"
                  style="margin-left: 5em; margin-top: -1em"
                >
                  <video
                    style="border-radius: 10px"
                    width="280"
                    controls
                    :src="show.value"
                  ></video>
                </div>
                <b-card
                  v-else-if="show.mselect == 'json'"
                  style="margin-left: 5em; margin-top: -1em"
                >
                  <h4>ยังไม่มีบริการ</h4>
                </b-card>
                <b-card
                  v-else-if="show.mselect == 'card'"
                  style="margin-left: 5em; margin-top: -1em"
                >
                  <h4>ยังไม่มีบริการ</h4>
                </b-card>
              </div>
            </div>
          </div>
        </vue-perfect-scrollbar>
      </section>
    </b-card>
    <div v-else>
      <div class="footer-line-show"></div>
      <b-card class="chat-widget" no-body>
        <b-card-header
          style="background-color: #353a40; color: #fff"
          @click="show = !show"
        >
          <div class="d-flex align-items-center" style="color: #fff">
            <feather-icon icon="ChevronDownIcon" size="30" />
            <h4 class="mb-0" style="color: #fff"><b>ดูเพิ่มเติม</b></h4>
          </div>
          <feather-icon icon="ChevronUpIcon" size="18" />
        </b-card-header>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BAvatar,
  BForm,
  BFormInput,
  BInputGroup,
  BButton,
  BDropdown,
  BDropdownItem,
  BCardText,
  BCardTitle,
  BCardBody,
  BFormTextarea,
  BImg,
  BBadge,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import api from "@/api";
export default {
  components: {
    BCard,
    BCardHeader,
    BAvatar,
    BForm,
    BFormInput,
    BInputGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardTitle,
    BCardBody,
    BFormTextarea,
    BImg,
    BBadge,
    // 3rd party
    VuePerfectScrollbar,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 200,
        wheelPropagation: false,
      },

      message_show: null,
      text: null,
      sticker: null,
      img: null,
      viode: null,
      json: null,
      lineoa_logo: localStorage.getItem("logo"),
      lineoa_name: localStorage.getItem("lineoa.name"),
      show: true,
    };
  },
  mounted() {
    this.loadOA();
  },
  methods: {
    data_preview(message_data) {
      this.message_show = message_data;

      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";
.footer-line-show {
  height: 700px;
}
</style>
